Mosaic.addPlugins([require('/usr/share/nginx/html/localmodules/tmv-pwa/packages/mageplaza-gtm/src/plugin/Gtm.plugin.js'),require('/usr/share/nginx/html/localmodules/tmv-pwa/packages/amasty-mostviewed/src/plugin/ProductLinksContainer.plugin.js'),require('/usr/share/nginx/html/localmodules/tmv-pwa/packages/magenear-helper/src/plugin/CategoryDetailsBeforeBreadcrumbs.plugin.ts')]);
/* eslint-disable react/forbid-dom-props */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { PureComponent, Suspense } from 'react';

import CategoryDetails from 'Component/CategoryDetails';
import CategoryItemsCount from 'Component/CategoryItemsCount';
import CategoryProductList from './../../component/CategoryProductList';
import { CategorySortField } from 'Component/CategorySort/CategorySort.type';
import ContentWrapper from 'Component/ContentWrapper';
import Loader from 'Component/Loader';
import TextPlaceholder from 'Component/TextPlaceholder';
import { TextPlaceHolderLength } from 'Component/TextPlaceholder/TextPlaceholder.config';
import { ReactElement } from 'Type/Common.type';
import { isCrawler, isSSR } from 'Util/Browser';
import BrowserDatabase from 'Util/BrowserDatabase';
import { setLoadedFlag } from 'Util/Request/LowPriorityLoad';
import { lowPriorityLazy } from 'Util/Request/LowPriorityRender';

import {
    CategoryDisplayMode,
    CategoryPageLayout,
    LAYOUT_KEY,
} from './CategoryPage.config';
import { CategoryPageComponentProps, CategoryPageComponentState } from './CategoryPage.type';

import './CategoryPage.style';

import Tooltip from "@material-ui/core/Tooltip";

import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    customTooltip: {
        backgroundColor: "rgba(64, 25, 25, 1)",
        fontSize: '12px',
        lineHeight: '12px',
        borderRadius: '20px',
        padding: '8px 12px'
    },
    customArrow: {
        color: "rgba(64, 25, 25, 1)"
    }
}));

const RenderTooltip = (content: any) => {
    const classes = useStyles();
    const {title, placement, button} = content;

    return (
        <>
            <Tooltip
                // onOpen={handleOpen}
                TransitionProps={{timeout: 300}}
                classes={{
                    tooltip: classes.customTooltip,
                    arrow: classes.customArrow,
                }}
                title={title}
                arrow
                placement={placement}
            >
                {button}
            </Tooltip>
        </>
    );
};

export const CategoryFilterOverlay = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "overlays-category" */ 'Component/CategoryFilterOverlay'
));
export const FilterIcon = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "category-misc" */ 'Component/FilterIcon'
));
export const GridIcon = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "category-misc" */ 'Component/GridIcon'
));
export const ListIcon = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "category-misc" */ 'Component/ListIcon'
));
export const CategorySort = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "category-misc" */ 'Component/CategorySort'
));

/** @namespace Route/CategoryPage/Component */
export class CategoryPageComponent<
P extends CategoryPageComponentProps = CategoryPageComponentProps,
S extends CategoryPageComponentState = CategoryPageComponentState,
> extends PureComponent<P, S> {
    static defaultProps: Partial<CategoryPageComponentProps> = {
        isContentFiltered: true,
        isMatchingListFilter: false,
        isCurrentCategoryLoaded: false,
        isMatchingInfoFilter: false,
        totalPages: 1,
        defaultPlpType: undefined,
        plpTypes: [],
        search: '',
        appliedFiltersCount: 0,
        selectedLayoutType: undefined,
    };

    state = {
        activeLayoutType: undefined,
    } as S;

    static getDerivedStateFromProps(props: CategoryPageComponentProps): Partial<CategoryPageComponentState> {
        const {
            isMobile,
            defaultPlpType,
            selectedLayoutType,
        } = props;

        /*
        * Use stored plpType from the BrowserDatabase
        * if there is one
        */
        const storedPlpType = BrowserDatabase.getItem<CategoryPageLayout>(LAYOUT_KEY) || selectedLayoutType;

        if (!isMobile) {
            const width = document.body.clientWidth;
            if (typeof storedPlpType !== 'undefined') {
                document.getElementById("CategoryPageLayoutButtons")?.classList.remove('layouts-2', 'layouts-3');
                if (width >= 1200 && width < 1299) {
                    document.getElementById("CategoryPageLayoutButtons")?.classList.add('layouts-3');
                    let activeLayoutType = (storedPlpType === 'list') ? CategoryPageLayout.LIST : CategoryPageLayout.GRID;
                    if (storedPlpType === CategoryPageLayout.GRID5) {
                        activeLayoutType = CategoryPageLayout.GRID4;
                        return { activeLayoutType };
                    }
                    if (storedPlpType === CategoryPageLayout.GRID4) {
                        activeLayoutType = CategoryPageLayout.GRID4;
                        return { activeLayoutType };
                    }
                    return { activeLayoutType };
                }else {
                    // document.getElementById("CategoryPageLayoutButtons")?.classList.remove('layouts-3');
                }
                if (width > 810 && width < 1200) {
                    document.getElementById("CategoryPageLayoutButtons")?.classList.add('layouts-2');
                    const activeLayoutType = (storedPlpType === 'list') ? CategoryPageLayout.LIST : CategoryPageLayout.GRID;
                    return { activeLayoutType };
                }else {
                    // document.getElementById("CategoryPageLayoutButtons")?.classList.remove('layouts-2');
                }
            }else {
                let activeLayoutType = selectedLayoutType || defaultPlpType;
                document.getElementById("CategoryPageLayoutButtons")?.classList.remove('layouts-2', 'layouts-3');
                if (width >= 1200 && width < 1299) {
                    document.getElementById("CategoryPageLayoutButtons")?.classList.add('layouts-3');
                    if (activeLayoutType === CategoryPageLayout.GRID5) {
                        activeLayoutType = CategoryPageLayout.GRID4;
                        return { activeLayoutType };
                    }
                    if (activeLayoutType === CategoryPageLayout.GRID4) {
                        activeLayoutType = CategoryPageLayout.GRID4;
                        return { activeLayoutType };
                    }
                    return { activeLayoutType };
                }else {
                    // document.getElementById("CategoryPageLayoutButtons")?.classList.remove('layouts-3');
                }
                if (width > 810 && width < 1200) {
                    document.getElementById("CategoryPageLayoutButtons")?.classList.add('layouts-2');
                    if (activeLayoutType === CategoryPageLayout.LIST) {
                        return { activeLayoutType };
                    }
                    if (activeLayoutType === CategoryPageLayout.GRID5) {
                        activeLayoutType = CategoryPageLayout.GRID;
                        return { activeLayoutType };
                    }
                }else {
                    // document.getElementById("CategoryPageLayoutButtons")?.classList.remove('layouts-2');
                }
            }

        }

        if (storedPlpType) {
            if (isMobile) {
                if ((storedPlpType || defaultPlpType) ===  CategoryPageLayout.LIST) {
                    const activeLayoutType = CategoryPageLayout.GRID5;
                    return { activeLayoutType };
                }
                if ((storedPlpType || defaultPlpType) ===  CategoryPageLayout.GRID4) {
                    const activeLayoutType = CategoryPageLayout.GRID;
                    return { activeLayoutType };
                }
                const activeLayoutType = storedPlpType || defaultPlpType;
                return { activeLayoutType };
            }else {
                const activeLayoutType = storedPlpType || defaultPlpType;
                return { activeLayoutType };
            }


        }

        if (isMobile) {
            if ((selectedLayoutType || defaultPlpType) ===  CategoryPageLayout.LIST) {
                const activeLayoutType = CategoryPageLayout.GRID5;
                return { activeLayoutType };
            }
            if ((storedPlpType || defaultPlpType) ===  CategoryPageLayout.GRID4) {
                const activeLayoutType = CategoryPageLayout.GRID;
                return { activeLayoutType };
            }
            const activeLayoutType = CategoryPageLayout.GRID;
            return { activeLayoutType };
        }else {
            const activeLayoutType = selectedLayoutType || defaultPlpType;
            return { activeLayoutType };
        }

    }

    componentDidMount(): void {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount(): void {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = this._handleResize.bind(this);

    _handleResize() {
        const width = document.body.clientWidth;
        if (width > 810 && width < 1299) {
            //@ts-ignore
            this.setState({isTablet: true})
        }else {
            //@ts-ignore
            this.setState({isTablet: false})
        }
    }

    displayProducts() {
        const { displayMode } = this.props;

        return displayMode === null
            || displayMode === CategoryDisplayMode.PRODUCTS
            || displayMode === CategoryDisplayMode.BOTH;
    }

    displayCmsBlock(): boolean {
        const { category: { display_mode } = {} } = this.props;

        return display_mode === CategoryDisplayMode.CMS_BLOCK
            || display_mode === CategoryDisplayMode.BOTH;
    }

    renderCategoryDetails(): ReactElement {
        const {
            category,
            isCurrentCategoryLoaded,
        } = this.props;
        const { isPrefetchValueUsed } = window;

        return (
            <CategoryDetails
              category={ category }
              isCurrentCategoryLoaded={ isPrefetchValueUsed || isCurrentCategoryLoaded }
            />
        );
    }

    renderFiltersCount(): ReactElement {
        const { appliedFiltersCount } = this.props;

        if (!appliedFiltersCount) {
            return null;
        }

        return (
            <span block="CategoryPage" elem="Subheading">
                { ` (${appliedFiltersCount})` }
            </span>
        );
    }

    renderFilterButton(): ReactElement {
        const {
            isContentFiltered,
            totalPages,
            category: { is_anchor },
            isSearchPage,
            isCurrentCategoryLoaded,
            isMatchingInfoFilter,
            onFilterButtonClick,
        } = this.props;

        if (!isMatchingInfoFilter) {
            return this.renderFilterButtonPlaceholder();
        }

        if ((!isContentFiltered && totalPages === 0) || (!is_anchor && !isSearchPage) || !isCurrentCategoryLoaded) {
            return null;
        }

        return (
            <button
              block="CategoryPage"
              elem="Filter"
              onClick={ onFilterButtonClick }
            >
                <Suspense fallback={ <div block="CategoryPage" elem="FilterPlaceholder" /> }>
                    <FilterIcon />
                </Suspense>
                <span>{ __('Filters') }</span>
                { this.renderFiltersCount() }
            </button>
        );
    }

    renderPlaceholder(block: string): ReactElement {
        return (
            <>
                <div block={ block } elem="SwatchList">
                    <div block={ block } elem="Placeholder" />
                    <div block={ block } elem="Placeholder" />
                    <div block={ block } elem="Placeholder" />
                </div>
                <Loader isLoading />
            </>
        );
    }

    renderFilterPlaceholder(): ReactElement {

        // return null;

        return (
            <p block="CategorySort" elem="Placeholder">
                <TextPlaceholder length={TextPlaceHolderLength.SHORT} />
            </p>
        )
        return (
            <div block="CategoryPage" elem="PlaceholderWrapper">
                <div block="CategoryPage" elem="PlaceholderContainer">
                    {/*<h3 block="CategoryPage" elem="PlaceholderHeading">*/}
                    {/*    { __('Shopping Options') }*/}
                    {/*</h3>*/}
                    <div block="CategoryPage" elem="PlaceholderList">
                        <div block="CategoryPage" elem="PlaceholderListItem" />
                        {/*<div block="CategoryPage" elem="PlaceholderListItem" />*/}
                        {/*<div block="CategoryPage" elem="PlaceholderListItem" />*/}
                    </div>
                    <Loader isLoading />
                </div>
            </div>
        );
    }

    renderFilterButtonPlaceholder(): ReactElement {
        return (
            <p block="CategoryPage" elem="FilterButtonPlaceholder">
                <TextPlaceholder length={ TextPlaceHolderLength.SHORT } />
            </p>
        );
    }

    renderFilterOverlay(): ReactElement {
        const {
            filters,
            selectedFilters,
            isMatchingInfoFilter,
            isMatchingListFilter,
            isSearchPage,
            totalPages
        } = this.props;
// console.clear();
// console.log(this.state);
// console.log(this.props);
        const { category: { is_anchor } } = this.props;

        if (!this.displayProducts() || !Object.keys(selectedFilters).length && !totalPages) {
            return null;
        }
// return this.renderFilterPlaceholder();
        return (
            <Suspense fallback={ this.renderFilterPlaceholder() || null }>
                <CategoryFilterOverlay
                  availableFilters={ filters }
                  customFiltersValues={ selectedFilters }
                  isMatchingInfoFilter={ isMatchingInfoFilter }
                  isCategoryAnchor={ !!is_anchor }
                  isSearchPage={ isSearchPage }
                  renderPlaceholder={ this.renderPlaceholder }
                  isMatchingListFilter={ isMatchingListFilter }
                />
            </Suspense>
        );
    }

    renderCategorySort(): ReactElement {
        const {
            sortFields,
            selectedSort,
            onSortChange,
            isMatchingInfoFilter,
            isCurrentCategoryLoaded,
            isMobile,
        } = this.props;

        const { options = [] } = sortFields;
        const updatedSortFields: CategorySortField[] = options.map(({ value: id, label }) => ({ id, label }));
        const { sortDirection, sortKey } = selectedSort;

        if (isMobile && !isMatchingInfoFilter) {
            return this.renderFilterButtonPlaceholder();
        }
// return this.renderFilterPlaceholder();
        return (
            <Suspense fallback={ this.renderFilterPlaceholder() || null }>
                <CategorySort
                  isCurrentCategoryLoaded={ isCurrentCategoryLoaded }
                  isMatchingInfoFilter={ isMatchingInfoFilter }
                  onSortChange={ onSortChange }
                  sortFields={ updatedSortFields }
                  sortKey={ sortKey }
                  sortDirection={ sortDirection }
                  showLayoutsText={ false }
                />
            </Suspense>
        );
    }

    renderLayoutButton(type: CategoryPageLayout): ReactElement {
        const {
            onGridButtonClick,
            onGrid4ButtonClick,
            onGrid5ButtonClick,
            onListButtonClick,
            isMobile
        } = this.props;

        if (isMobile && type === CategoryPageLayout.GRID4 || isMobile && type === CategoryPageLayout.LIST) {
            return null;
        }

        const { activeLayoutType } = this.state;

        switch (type) {
        case CategoryPageLayout.GRID:
            return <RenderTooltip title={(isMobile) ? '2 Column' : '3 Column'} placement="top" button={
                <button
                  key={ type }
                  onClick={ onGridButtonClick }
                  mix={ {
                      block: CategoryPageLayout.GRID,
                      mods: { isActive: activeLayoutType === CategoryPageLayout.GRID },
                  } }
                  aria-label="grid"
                >
                    <Suspense fallback={ this.renderFilterPlaceholder() || null }>
                        <GridIcon isMobile={isMobile} gridLayout={CategoryPageLayout.GRID} isActive={ activeLayoutType === CategoryPageLayout.GRID } />
                        </Suspense>
                </button>
            }/>
        case CategoryPageLayout.LIST:
            return <RenderTooltip title='1 Column' placement="top" button={
                <button
                  key={ type }
                  onClick={ onListButtonClick }
                  mix={ {
                      block: CategoryPageLayout.LIST,
                      mods: { isActive: activeLayoutType === CategoryPageLayout.LIST },
                  } }
                  aria-label="list"
                >
                    <Suspense fallback={ this.renderFilterPlaceholder() || null }>
                        <ListIcon isMobile={isMobile} isActive={ activeLayoutType === CategoryPageLayout.LIST } />
                        </Suspense>
                </button>
            }/>
            case CategoryPageLayout.GRID4:
            return <RenderTooltip title='4 Column' placement="top" button={
                <button
                  key={ type }
                  onClick={ onGrid4ButtonClick }
                  mix={ {
                      block: CategoryPageLayout.GRID4,
                      mods: { isActive: activeLayoutType === CategoryPageLayout.GRID4 },
                  } }
                  aria-label="grid4"
                >
                    <Suspense fallback={ this.renderFilterPlaceholder() || null }>
                        <GridIcon isMobile={isMobile} gridLayout={CategoryPageLayout.GRID4} isActive={ activeLayoutType === CategoryPageLayout.GRID4 } />
                        </Suspense>
                </button>
            }/>
            case CategoryPageLayout.GRID5:
            return <RenderTooltip title={(isMobile) ? '1 Column' : '5 Column'} placement="top" button={
                <button
                  key={ type }
                  onClick={ onGrid5ButtonClick }
                  mix={ {
                      block: CategoryPageLayout.GRID5,
                      mods: { isActive: activeLayoutType === CategoryPageLayout.GRID5 },
                  } }
                  aria-label="grid5"
                >
                    <Suspense fallback={ this.renderFilterPlaceholder() || null }>
                        <GridIcon isMobile={isMobile} gridLayout={CategoryPageLayout.GRID5} isActive={ activeLayoutType === CategoryPageLayout.GRID5 } />
                        </Suspense>
                </button>
            }/>
        default:
            return false;
        }
    }

    renderLayoutButtons(): ReactElement {
        const { plpTypes, isMobile, sortFields: {options} } = this.props;


        /*
        * So far there is only two types of
        * the Storefront list modes
         */
        // if (plpTypes.length !== 2) {
        //     return null;
        // }

        // if (typeof options === 'undefined') {
        //     return this.renderFilterPlaceholder();
        // }

        return (
            <div id="CategoryPageLayoutButtons" block="CategoryPage" elem="LayoutButtons">
                {/*{(isMobile) ? '' : (typeof options !== 'undefined') ? <span>Layouts:</span> : ''}*/}
                { plpTypes.map(this.renderLayoutButton.bind(this)) }
            </div>
        );
    }

    renderItemsCount(isVisibleOnMobile = false): ReactElement {
        const { isMatchingListFilter, isMobile } = this.props;

        if ((isVisibleOnMobile && !isMobile) || (!isVisibleOnMobile && isMobile)) {
            return null;
        }

        return (
            <CategoryItemsCount
              isMatchingListFilter={ isMatchingListFilter }
            />
        );
    }

    renderCategoryProductList(): ReactElement {
        const {
            filter,
            search,
            selectedSort,
            selectedFilters,
            isMatchingListFilter,
            isCurrentCategoryLoaded,
            isMatchingInfoFilter,
            isMobile
        } = this.props;

        const { activeLayoutType } = this.state;

        if (!this.displayProducts()) {
            setLoadedFlag();

            return null;
        }
        return (
            <div
              block="CategoryPage"
              elem="ProductListWrapper"
              mods={ { isPrerendered: isSSR() || isCrawler() } }
            >
                { (isMobile) ? this.renderMiscellaneous() : "" }
                { this.renderItemsCount(true) }
                <CategoryProductList
                  filter={ filter }
                  search={ search }
                  sort={ selectedSort }
                  selectedFilters={ selectedFilters }
                  isCurrentCategoryLoaded={ isCurrentCategoryLoaded }
                  isMatchingListFilter={ isMatchingListFilter }
                  isMatchingInfoFilter={ isMatchingInfoFilter }
                  layout={ activeLayoutType || CategoryPageLayout.GRID }
                />
            </div>
        );
    }

    renderCmsBlock(): ReactElement {
        const { category: { cms_block }, isCurrentCategoryLoaded } = this.props;

        if (!cms_block || !this.displayCmsBlock() || !isCurrentCategoryLoaded) {
            return null;
        }

        const { content, disabled } = cms_block;

        if (disabled) {
            return null;
        }

        return (
            <div
              block="CategoryPage"
              elem="CMS"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={ { __html: content } }
            />
        );
    }

    renderMiscellaneous(): ReactElement {
        const { totalItems, isMobile } = this.props;

        if (totalItems === 0 || !this.displayProducts()) {
            // return <aside block="CategoryPage" elem="Miscellaneous" mods={ { noResults: true } } />;
        }

        return (
            <aside block="CategoryPage" elem="Miscellaneous">
                <div block="CategoryPage" elem="MiscellaneousLeft">
                { this.renderFilterOverlay() }
                { this.renderCategorySort() }
                { this.renderItemsCount() }
                </div>

                <div
                  block="CategoryPage"
                  elem="MiscellaneousLayoutWrapper"
                >
                  <div
                    block="CategoryPage"
                    elem="LayoutWrapper"
                    mods={ { isPrerendered: isSSR() || isCrawler() } }
                  >
                      { this.renderLayoutButtons() }
                  </div>
                  {/*<div*/}
                  {/*  block="CategoryPage"*/}
                  {/*  elem="LayoutWrapper"*/}
                  {/*  mods={ { isPrerendered: isSSR() || isCrawler() } }*/}
                  {/*>*/}
                      {/* { this.renderFilterButton() } */}
                  {/*</div>*/}
                </div>
            </aside>
        );
    }

    renderContent(): ReactElement {

        const {
            isMobile,
        } = this.props;

        return (
            <>
                <div className='filter-st'>{ this.renderFilterOverlay() }</div>
                { this.renderCategoryDetails() }
                { this.renderCmsBlock() }
                { (isMobile) ? '' :  this.renderMiscellaneous() }
                { this.renderCategoryProductList() }
            </>
        );
    }

    render(): ReactElement {
        const hideProducts = !this.displayProducts();
        const { totalItems } = this.props;

        return (
            <main block="CategoryPage" mods={ { noResults: totalItems === 0 } }>
                <ContentWrapper
                  wrapperMix={ {
                      block: 'CategoryPage',
                      elem: 'Wrapper',
                      mods: { hideProducts },
                  } }
                  label="Category page"
                >
                    { this.renderContent() }
                </ContentWrapper>
            </main>
        );
    }
}

export default CategoryPageComponent;
