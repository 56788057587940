import Mostviewed from "./../component/Mostviewed";
import ContentWrapper from "@scandipwa/scandipwa/src/component/ContentWrapper";
import {LinkedProductType,} from '@scandipwa/scandipwa/src/store/LinkedProducts/LinkedProducts.type';

const containerRender = (args, callback, instance) => {

    const {
        linkType,
        linkedProducts: {
            [linkType]: {
                items = []
            } = {}
        }
    } = instance.props;

    if (items.length === 0) {
        // return null;
    }
    // console.log(linkType);
    return (
        <>
            <Mostviewed
                {...instance.containerProps()}
            />
        </>
    );
};

const componentRender = (args, callback, instance) => {
    const {areDetailsLoaded} = instance.props;

    if (!areDetailsLoaded) {
        return null;
    }

    return (
        <ContentWrapper
            mix={{block: 'ProductLinks'}}
            wrapperMix={{block: 'ProductLinks', elem: 'Wrapper'}}
            label={__('Linked products')}
        >
            {instance.renderHeading()}
            <ul block="ProductLinks" elem="List">
                {instance.renderItems()}
            </ul>
        </ContentWrapper>
    );
};

const renderAdditionalSections = (args, callback, instance) => {
    const {areDetailsLoaded} = instance.props;

    if (!areDetailsLoaded) {
        return null;
    }

    return (
        <>
            <Mostviewed
                linkType={LinkedProductType.RELATED}
                // title={ __('Recommended for you') }
                // areDetailsLoaded={ areDetailsLoaded }
            />
        </>
    );
};

const categoryPageComponentRender = (args, callback, instance) => {

    const hideProducts = !instance.displayProducts();
    const {totalItems, category} = instance.props;

    return (
        <main block="CategoryPage" mods={{noResults: totalItems === 0}}>
            <ContentWrapper
                wrapperMix={{
                    block: 'CategoryPage',
                    elem: 'Wrapper',
                    mods: {hideProducts}
                }}
                label="Category page"
            >
                {instance.renderContent()}
            </ContentWrapper>
            <Mostviewed location={window.location.pathname}/>
        </main>
    );
};

export default {
    'Component/ProductLinks/Container': {
        'member-function': {
            render: containerRender
        }
    },
    'Component/ProductLinks/Component': {
        'member-function': {
            render: componentRender
        }
    }
    ,
    'Route/ProductPage/Component': {
        'member-function': {
            renderAdditionalSections: renderAdditionalSections
        }
    },
    'Route/CategoryPage/Component': {
        'member-function': {
            render: categoryPageComponentRender
        }
    }
};
