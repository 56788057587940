import {
    CategoryDetailsComponent as SourceCategoryDetailsComponent,
} from 'SourceComponent/CategoryDetails/CategoryDetails.component';


import Html from 'Component/Html';
import { ReactElement } from 'Type/Common.type';

import './CategoryDetails.style';

export class CategoryDetailsComponent extends SourceCategoryDetailsComponent {

    renderCategoryName(): ReactElement {
        const {
            isCurrentCategoryLoaded,
            category: { name, id },
        } = this.props;
        const {
            isPrefetchValueUsed,
        } = window;

        if (!id && !name && !isPrefetchValueUsed) {
            return null;
        }

        // console.log(this.state);
        // console.log(this.props);
        return (
            <h1 block="CmsPage" elem="Heading" mix={{block: (isCurrentCategoryLoaded) ? '' : 'loading'}}>
                { this.renderCategoryText() }
            </h1>
        );
    }

    renderCategoryDescriptionPlaceholder(): ReactElement {
        const {category:{description}} = this.props;

        if (typeof description === 'undefined' || !description) {
            return null;
        }

        let cleanDescription = description.replace(/<\/?[^>]+(>|$)/g, "");
        let cleanDescriptionWrapper = '<p>';
        let i = 0;
        while (i < cleanDescription.length) {
            cleanDescriptionWrapper += "<span class='text-loading'></span>";
            i++;
        }
        cleanDescriptionWrapper += "</p>";

        return (
            <Html content={ cleanDescriptionWrapper } />

        );
    }
}

export default CategoryDetailsComponent;
